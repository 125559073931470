import styled from 'styled-components';
import colors from '../../configs/colors';
import fontSizes from '../../configs/fontSizes'; 
import constants from '../../configs/constants';

const BaseInputStyles = `
  outline: none;
  border: none;
  border-radius: ${constants.borderRadius}px;
  border: 1px solid ${colors.border};
  font-size: ${fontSizes.regular}px;
  padding: 0px 10px;
  resize: none;
  box-shadow: none;
  -webkit-box-shadow: none;
`;

const CustomInputComponent = styled.input`
  ${BaseInputStyles}
`;

const CustomInputArea = styled.textarea`
  ${BaseInputStyles}
  overflow: hidden;
  min-height: 30px;
  width: 100%;
  font-family: inherit;
  line-height: 30px;
`;

export { BaseInputStyles, CustomInputComponent, CustomInputArea }