import React, { useEffect, useRef } from 'react';
import { BaseInputStyles, CustomInputComponent, CustomInputArea } from './index.style';

const CustomInput = ({ isExpandable = false, ...props }) => {
  const textAreaRef = useRef(null);

  const adjustHeight = () => {
    const textarea = textAreaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
        // height is maximum of min height and size to match the content
      textarea.style.height = `${Math.max(30, textarea.scrollHeight)}px`;
    }
  };

  useEffect(() => {
    if (isExpandable && props.value) {
      adjustHeight();
    }
  }, [isExpandable, props.value]);

  if (isExpandable) {
    return (
      <CustomInputArea
        ref={textAreaRef}
        rows={1}
        {...props}
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(e);
          }
          adjustHeight();
        }}
      />
    );
  }
  return <CustomInputComponent type="text" {...props} />;
};
  
export default CustomInput;