import networking from "../configs/networking"


async function getComponentData() {
    const URL = networking.apiAddress + 'component_data';
    const OPTIONS = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
    };
    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }
    return response.json();
} export { getComponentData }

async function uploadCSV(file) {
    console.log("now in uploadCSV api")
    const URL = networking.apiAddress + 'upload_csv';

    const formData = new FormData();
    formData.append('file', file);

    const OPTIONS = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: formData
    };
    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }
    return response;
}

async function processComments(comment, step, component, uuid,tempNumber) {
    const URL = networking.apiAddress + 'process_comment';

    const OPTIONS = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: JSON.stringify({
            'unprocessed_comments': comment,
            'step': step,
            'component': component,
            'tracking_uuid' : uuid,
            'tempNumber': tempNumber
        })
    };
    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }

    return response.json();
}

async function transcribeAudio(audio, language, component, step, uuid, tempNumber) {
    const URL = networking.apiAddress + 'transcribe';
    // Wrap the audio file in form data
    const audioBlob = await fetch(audio).then(r => r.blob());
    const formData = new FormData();
    formData.append('audio_file', audioBlob, 'recording.webm');
    formData.append('language', language);
    formData.append('component', component);
    formData.append('step', step);
    formData.append('tracking_uuid', uuid);
    formData.append('tempNumber', tempNumber);

    const OPTIONS = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: formData,
    };

    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }

    return response.json();
}

async function processAnnotationAnswers(comment, step, component, uuid, tempNumber) {
    const URL = networking.apiAddress + 'review';
    const OPTIONS = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: JSON.stringify({
            'notes_with_feedback': comment,
            'step' : step,
            'component' : component,
            'tracking_uuid' : uuid,
            'tempNumber': tempNumber
        })
    };

    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }

    return response.json();
}

async function login(username, password) {
    const URL = networking.apiAddress + 'auth/login';
    const OPTIONS = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'username': username,
            'password': password
        })
    };

    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }

    const data = await response.json();

    // If the login is successful, store the token
    if (data.access_token) {
        localStorage.setItem('jwtToken', data.access_token);
    }

    return data;
}

async function validateToken() {
    const URL = networking.apiAddress + 'auth/validate';
    const OPTIONS = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
    };

    const response = await fetch(URL, OPTIONS);

    if (response.status === 200) {
        return true;
    } else {
        return false;
    }
}

async function guidanceList(component, step) {
    const URL = networking.apiAddress + 'guidance_list';
    const OPTIONS = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: JSON.stringify({
            'component': component,
            'step': step,
        })
    };

    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }

    return response.json();
}

async function log_edited_admin(step, component, original_admin, edited_admin) {
    const URL = networking.apiAddress + 'edited_admin';
    const OPTIONS = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: JSON.stringify({
            'step': step,
            'component': component,
            'original_admin': original_admin,
            'edited_admin': edited_admin
        })
    };

    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }

    return response.json();
}

export { processComments, transcribeAudio, processAnnotationAnswers, login, validateToken, guidanceList, uploadCSV, log_edited_admin }   
