import logo from './assets/logo.svg';
import './App.css';
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
import Main from './views/Main';
import Login from './views/Login';
import { StepsProvider} from "./contexts/StepsContext";
import { LanguageProvider } from './contexts/LanguageContext';

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <LanguageProvider>
        <StepsProvider>
          <Main />
        </StepsProvider>
      </LanguageProvider>
    ),
  },
  {
    path: "/login",
    element: ( <LanguageProvider>
      <Login />,
      </LanguageProvider>)
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
