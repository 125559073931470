// index.js
import React, { useState } from 'react';
import CustomButton from '../../components/CustomButton';
import {
  TranscriptContainer,
  Timestamp,
  TranscriptText,
  ButtonContainer
} from './index.style';
import { useLanguage } from '../../contexts/LanguageContext';

const TranscriptDisplay = ({ transcript, onEdit, onDelete }) => {

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const { translate, currentLanguage, setCurrentLanguage } = useLanguage();

  return (
    <TranscriptContainer>
      <Timestamp>{transcript.timestamp}</Timestamp>
      <TranscriptText>{transcript.text}</TranscriptText>
      <ButtonContainer>
        <CustomButton
          text={translate("edit")}
          iconName="FaEdit"
          onClick={onEdit}
          style={{ padding: '2px 5px', fontSize: '0.7em' }}
        />
        <CustomButton
          text={translate("delete")}
          iconName="FaTrash"
          onClick={onDelete}
          style={{ padding: '2px 5px', fontSize: '0.7em' }}
        />
      </ButtonContainer>
    </TranscriptContainer>
  );
};

export default TranscriptDisplay;
