import React from 'react';
import {ModalOverlay, ModalContent, Title, Description, Input, Button} from './index.style';
import { useLanguage } from '../../contexts/LanguageContext';


const OrderNumberModal = ({ isOpen, onSubmit, tempOrderNr, setTempOrderNr }) => {
  if (!isOpen) return null;
  const { translate, currentLanguage, setCurrentLanguage } = useLanguage();

  return (
    <ModalOverlay>
      <ModalContent>
        <Title>{translate("enterOrderNumber")}</Title>
        <Description>{translate("pleaseEnter")}</Description>
        <Input
          value={tempOrderNr}
          onChange={(e) => setTempOrderNr(e.target.value)}
          placeholder={translate("enterOrderNumber")}
        />
        <Button
          onClick={() => onSubmit(tempOrderNr)}
          disabled={!tempOrderNr}
        >
          {translate("continue")}
        </Button>
      </ModalContent>
    </ModalOverlay>
  );
};

export default OrderNumberModal;