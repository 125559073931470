import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
`;

export const Description = styled.p`
  color: #666;
  margin-bottom: 1.5rem;
`;

export const Input = styled.input`
  width: 93.5%;
  padding: 0.75rem;
  margin: 0 auto;
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: #00A1E4;
    box-shadow: 0 0 0 2px rgba(0, 161, 228, 0.2);
  }
`;

export const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: ${props => props.disabled ? '#ccc' : '#00A1E4'};
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.disabled ? '#ccc' : '#0091d4'};
  }
`;