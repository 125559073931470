import React, {useEffect, useState} from 'react';
import Wrapper from '../../components/Wrapper';
import './style.css';
import { LeftSide, MainContainer, ProcessedCommentContainer, ProcessedCommentWrapper, RightSide, SideContentWrapper, SideHeadline, SuggestionsContainer, SuggestionText, SuggestionWindow} from './index.style';
import CustomButton from '../../components/CustomButton';
import CommentHighlight from '../../components/CommentHighlight';
import * as api from '../../utils/api';
import Container from '../../components/Container';
import Navbar from '../../components/Navbar';
import CustomInput from '../../components/CustomInput';
import CustomSelect from '../../components/CustomSelect';
import {useStepsContext} from '../../contexts/StepsContext';
import TranscriptEditor from '../../components/TranscriptEditor';
import TranscriptDisplay from '../../components/TranscriptDisplay';
import VoiceMemoDisplay from '../../components/VoiceMemoDisplay';
import AnnotationRecordButton from '../../components/AnnotationRecordButton';
import DropZone from '../../components/DropZone';
import GuidanceListPopup from '../../components/GuidanceList';
import UserGuide from '../../components/UserGuide';
import Spinner from '../../components/Spinner';
import OrderNumberModal from '../../components/OrderNumberPopup';
import { v4 as uuidv4 } from 'uuid';
import { useLanguage } from '../../contexts/LanguageContext';

function Main() {
    const { updateStepData, getStepData } = useStepsContext();
    
    const [selectedStep, setSelectedStep] = useState();
    const [selectedHighlight, setSelectedHighlight] = useState(null);
    const [hoveredHighlight, setHoveredHighlight] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);

    const [audioRecordings, setAudioRecordings] = useState([]);
    const [isTranscribing, setIsTranscribing] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const { transcripts = [], processedComments, parts, annotations } = getStepData(selectedStep);

    const [isCopied, setIsCopied] = useState(false);
    const [editingTranscript, setEditingTranscript] = useState(null);

    const [componentOptions, setComponentOptions] = useState([]);
    const [componentMenuOptions, setComponentMenuOptions] = useState([]);

    const [selectedComponent, setSelectedComponent] = useState('oven');
    const [selectedMenu, setSelectedMenu] = useState([]);
    const [recordingStartTime, setRecordingStartTime] = useState(null);


    const [selectedGuidance, setSelectedGuidance] = useState(null);
    const [showGuidancePopup, setShowGuidancePopup] = useState(false);

    const [showUserGuide, setShowUserGuide] = useState(false);

    const [lastProcessedTranscripts, setLastProcessedTranscripts] = useState({});

    const [isEditingOutput, setIsEditingOutput] = useState(false);
    const [editedParts, setEditedParts] = useState([]);
    const [showNumberInput, setShowNumberInput] = useState(false);
    const [orderNumber, setOrderNumber] = useState('');
    const [tempOrderNr, setTempOrderNr] = useState('');
    const [showOrderModal, setShowOrderModal] = useState(true);
    const { translate, currentLanguage, setCurrentLanguage } = useLanguage();

    const TRANSCRIPT_LANGUAGES = [
        { value: 'nl', text: translate("dutch") },
        { value: 'en', text: translate("english") }
    ]

    useEffect(() => {
        if (!orderNumber) {
            setShowOrderModal(true);
        }
    }, []);

    const handleOrderNumSave = () => {
        const newUuid = uuidv4();
        setTrackingUuid(newUuid);
        console.log("current UUID: ", newUuid)

        setOrderNumber(tempOrderNr);
        setShowOrderModal(false);
        setShowNumberInput(false);
        setTempOrderNr('');

        const selectedMenu = componentMenuOptions.find(item => item.component === selectedComponent);
        if (selectedMenu) {
            selectedMenu.steps.forEach(step => {
                updateStepData(step, {
                    transcripts: [],
                    processedComments: '',
                    parts: [],
                    annotations: []
                });
            });
        }
    };

    const handleLanguageChange = (e) => {
        setCurrentLanguage(e.target.value);
    };

    const handleNumberButtonClick = () => {
        setShowOrderModal(true);
    };

    const [activeIndex, setActiveIndex] = useState(0);

    const [trackingUuid, setTrackingUuid] = useState(() => {
        // Generate new UUID on page load
        return uuidv4();
    });


    useEffect(() => {
        if (parts.length > 0) {
            setEditedParts(parts.map(part => part.text)); // Sync editedParts with parts text
        }
    }, [parts]);

    useEffect(() => {
        setIsEditingOutput(false);
    }, [selectedStep]);

    const log_edited_admin = () => {
        console.log("now in log_edited_admin");
        console.log("editedParts", editedParts);
        console.log("parts", parts);
    
        const isNotEqual = editedParts.length !== parts.length || 
            !editedParts.every((editedText, index) => editedText === parts[index].text);
    
        if (isNotEqual) {
            const cleanedOriginalAdmin = parts.map(part => part.text).join('\n');
            const cleanedEditedAdmin = editedParts.join('\n');

            api.log_edited_admin(selectedStep, selectedComponent, cleanedOriginalAdmin, cleanedEditedAdmin);
        }
    };
    
    const goToNextStep = () => {
        const currentIndex = selectedMenu.indexOf(selectedStep);
        if (currentIndex < selectedMenu.length - 1) {
            setSelectedStep(selectedMenu[currentIndex + 1]);
        }
    };
    
    const goToPreviousStep = () => {
        const currentIndex = selectedMenu.indexOf(selectedStep);
        if (currentIndex > 0) {
            setSelectedStep(selectedMenu[currentIndex - 1]);
        }
    };

    const handleToggleEditOutput = () => {
        if (isEditingOutput) {
            // Save edited output
            const newParts = parts.map((part, index) => ({
                ...part,
                text: editedParts[index] || part.text
            }));
            updateStepData(selectedStep, { parts: newParts });
        }
        setIsEditingOutput(!isEditingOutput); 
    
        log_edited_admin();
        // api.log_edited_admin(selectedStep, selectedComponent, parts, editedParts);
    };

    const handleEditPartChange = (index, value) => {
        const updatedEditedParts = [...editedParts];
        updatedEditedParts[index] = value;
        setEditedParts(updatedEditedParts);
    };
        
            
    //check if something changed in the transcript compared to the previous version
    const hasTranscriptChanged = () => {
        const lastTranscripts = lastProcessedTranscripts[selectedStep] || []; // Default to an empty array
        return JSON.stringify(transcripts) !== JSON.stringify(lastTranscripts);
    };


    useEffect(() => {
        if (hasTranscriptChanged()) {
            processComments();
            setLastProcessedTranscripts(prevState => ({
                ...prevState,
                [selectedStep]: transcripts
            }));
        }
    }, [transcripts, selectedStep]);

    const getTextToCopy = () => {
        return parts.map(part => part.text).join('');
    };

    const handleCopy = async () => {
        await navigator.clipboard.writeText(getTextToCopy());
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
    };

    useEffect(() => {
        const fetchComponentData = async () => {
          const data = await api.getComponentData(); // Call the actual data-fetching function

                const transformedOptions = transformComponentData(data.component_data);
                setComponentOptions(transformedOptions);

                const transformedMenuItems = transformComponentStepsData(data.component_data);
                console.log("transformedMenuItems", transformedMenuItems);
                setComponentMenuOptions(transformedMenuItems);
                console.log("componentMenuOptions in function 1", componentMenuOptions);

                // Set initial component and step only if we have valid data
                if (transformedOptions.length > 0 && transformedOptions[0].options.length > 0) {
                    const initialComponent = transformedOptions[0].options[0].value;
                    setSelectedComponent(initialComponent);

                    const initialMenu = transformedMenuItems.find(item => item.component === initialComponent);
                    if (initialMenu && initialMenu.steps.length > 0) {
                        setSelectedStep(initialMenu.steps[0]);
                    }
                }
            }

        fetchComponentData(); // Call the async function once
      }, []); // Empty dependency array to run only on initial render

      const transformComponentData = (componentData) => {
        const categories = {};

        componentData.component_name.forEach((name, index) => {
            const category = componentData.component_category[index];
            if (!categories[category]) {
                categories[category] = {
                    category,
                    options: []
                };
            }
                categories[category].options.push({ value: name, text: name });
        });
   
        return Object.values(categories);

    };
    
    useEffect(() => {
        // Find the menu items corresponding to the selected component
        const foundMenu = componentMenuOptions.find(item => item.component === selectedComponent);


        if (foundMenu) {
          setSelectedMenu(foundMenu.steps);
          // Set the first step of the selected component if no step is selected
            if (!selectedStep || !foundMenu.steps.includes(selectedStep)) {
                setSelectedStep(foundMenu.steps[0]);
            }
        }

      }, [selectedComponent, selectedStep, componentMenuOptions]);  // Add componentMenuOptions to dependency array


      useEffect(() => {

      }, [componentMenuOptions]);  // This will log the updated value of componentMenuOptions after it changes

    
    const transformComponentStepsData = (componentData) => {
        
        const menuItems = componentData.component_name.map((name, index) => {
            const steps = componentData.component_steps[index]
            .map(step => step.trim()) // Trim whitespace

            return {
                component: name,
                steps: steps
            };
        });
    
        return menuItems;
    };


    const handleComponentChange = (e) => {
        const newComponent = e.target.value;
        setSelectedComponent(newComponent);
        setTrackingUuid(uuidv4()); // Create new UUID for new component

        const selectedMenu = componentMenuOptions.find(item => item.component === newComponent);
        if (selectedMenu) {
            // Reset selected step to the first step of the selected component
            setSelectedStep(selectedMenu.steps[0]);

            // Clear data for all steps of the new component
            selectedMenu.steps.forEach(step => {
              updateStepData(step, {
                transcripts: [],
                processedComments: '',
                parts: [],
                annotations: []
              });
            });
          } else {
            // Handle the case when the component is not found (optional)
            console.error('Component not found:', newComponent);
          }
    };

    const handleAreaClick = (event) => {
        // create a new note if the user clicks directly on the container
        if (event.target === event.currentTarget) {
            handleNewNote();
        }
    };

    const handleEditTranscript = (index) => {
        setEditingTranscript({...transcripts[index]});
    };


    // moved difference in saving edited notes and saving new notes differently up to the function
    const handleSaveTranscript = (editedTranscript) => {
        const existingIndex = transcripts.findIndex(t => t.timestamp === editedTranscript.timestamp);
        let updatedTranscripts;
    
        if (existingIndex !== -1) {
            // Edit existing transcript
            updatedTranscripts = transcripts.map((t, index) =>
                index === existingIndex ? editedTranscript : t
            );
        } else {
            // Add new one
            updatedTranscripts = [...transcripts, editedTranscript];
        }
    
        // Update step data with new/edited transcripts
        updateStepData(selectedStep, { transcripts: updatedTranscripts });
        // No need to call processComments here directly
    };

    // TODO: Fix bug with too many process comments calls without changed transcript
    // Add useEffect to trigger processComments after transcripts are updated
    // useEffect(() => {
    //     if (transcripts.length > 0) {
    //         console.log("length > 0")
    //         processComments();
    //     }
    // }, [transcripts]);  // Only run when transcripts are updated

    
    const handleDeleteTranscript = (index) => {
        const updatedTranscripts = transcripts.filter((_, i) => i !== index);
        console.log("Updated Transcripts:", updatedTranscripts);
    
        if (updatedTranscripts.length === 0) {
            console.log("All transcripts deleted, processing comments");
            processComments();
        }
    
        updateStepData(selectedStep, { transcripts: updatedTranscripts });
        }
    
    
    const handleNewNote = () => {
        const newTranscript = {
            timestamp: new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', second:'2-digit'}),
            text: '',
            carriedOver: false
        };
        setEditingTranscript(newTranscript);
    };


    useEffect(() => {
        // Check token, redirect to login if doesn't exist
        if (!localStorage.getItem('jwtToken')) {
            window.location.href = '/login';
        }

        // Check if the user is logged in
        api.validateToken().then(r => {
            if (!r)
                window.location.href = '/login';
        }).catch(e => {});

         // Ask for microphone access
         if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                const mimeType = 'audio/webm;codecs=opus';
                const newMediaRecorder = new MediaRecorder(stream, {mimeType});
                setMediaRecorder(newMediaRecorder);

                newMediaRecorder.ondataavailable = e => {
                    const audioData = e.data;
                    const audioSrc = URL.createObjectURL(audioData);
                    const timestamp = new Date().toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', second: '2-digit'});
                    setAudioRecordings(prevRecordings => [...prevRecordings, { url: audioSrc, timestamp }]);
                };
            });
        }
    }, []);

    useEffect(() => {
        // transcibe most recent recording added to the array
        const latestRecording = audioRecordings[audioRecordings.length - 1];
        if (latestRecording) {
            console.log('New audio recording added:', latestRecording);
            transcribeAudio(latestRecording);
        }
    }, [audioRecordings]);

    const startRecording = () => {
        if (mediaRecorder) {
            if (!isRecording) {
                setIsRecording(true);
                setRecordingStartTime(Date.now());
                mediaRecorder.start();
                console.log(mediaRecorder.state);
                console.log("recorder started");
            } else {
                setIsRecording(false);
                mediaRecorder.stop();
                console.log(mediaRecorder.state);
                console.log("recorder stopped");
            }
        }
    };

    useEffect(() => {
        setShowGuidancePopup(false);
    }, [selectedStep]);
    
    useEffect(() => {
        setIsRecording(false);
    }, [selectedStep]);
    
    useEffect(() => {
        // Function to handle the spacebar press
        const handleSpacebarPress = (event) => {
            if (!editingTranscript && 
                !['input', 'textarea'].includes(document.activeElement.tagName.toLowerCase())) {
            if (event.code === 'Space' || event.keyCode === 32) {
                event.preventDefault(); // Prevents default spacebar scrolling behavior
                console.log('Spacebar pressed');
                startRecording(); // Call the recording function
            }
        }
        };
        // Add event listener for keydown
        window.addEventListener('keydown', handleSpacebarPress);
        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('keydown', handleSpacebarPress);
        };
    }, [startRecording], [editingTranscript]);


    /*
        - This function parses the annotated parts of the text and prepares them for rendering
        - It returns an array of (all) parts of the text and an array of annotations (annotated parts of the text)
    */
    const parseText = (apiText) => {
        const segments = apiText.split(/(?=-)|\n\s*\n/);

        let parts = [];
        let annotations = [];

        console.log('jonas 1', apiText);

        let segmentCounter = 0;

        segments.forEach((segment) => {
            // Check if the segment is annotated (contains a question)
            const annotationMatch = segment.match(/- \{"text": "(.*?)", "question": "(.*?)"\}/);

            if (annotationMatch) {
                const [, text, question] = annotationMatch;
                const annotationAnswer = '';
                const predefinedAnswer = '';

                parts.push({
                    text,
                    question,
                    isAnnotated: true,
                    index: segmentCounter-1,
                    annotationAnswer,
                    predefinedAnswer,
                });

                annotations.push({
                    text,
                    question,
                    index: annotations.length,
                    annotationAnswer,
                    predefinedAnswer,
                });
            } else {
                // Non-annotated text
                parts.push({ text: segment, index: segmentCounter });
            }

            segmentCounter++;
        });

        console.log('jonas 2', parts)
        return { parts, annotations };
    };

    /*
        - This function is called when the user clicks on the "Transcribe" button
        - It sends the audio file to the backend
        - It sends the selected language and the selected component to the backend
        - The backend transcribes the audio and returns the unprocessed comments
        - The transcription is displayed on the left side of the UI
    */
    const transcribeAudio = (recording) => {
        console.log('transcribing...', recording.url);
        setIsTranscribing(true);
        api.transcribeAudio(recording.url, currentLanguage, selectedComponent, selectedStep, trackingUuid,orderNumber).then(r => {
            const newTranscript = {
                timestamp: recording.timestamp,
                text: r['unprocessed_comments'],
                carriedOver: false
            };
            updateStepData(selectedStep, {
                transcripts: [...transcripts, newTranscript]
            });
            setIsTranscribing(false);
        });
    };

    /*
        - This function is called when the user clicks on the "Process comments" button
        - It sends the unprocessed comments to the backend
        - The backend processes the comments and returns the processed comments with annotations
        - Annotations are parsed and saved in the state to display them in the UI
    */
        const processComments = () => {
            setIsProcessing(true);
            console.log("processingComment")
            const unprocessedComments = transcripts.map(t => `[${t.timestamp}] ${t.text}`).join('\n\n');
            api.processComments(unprocessedComments, selectedStep, selectedComponent, trackingUuid, orderNumber).then(r => {


                Object.entries(r).forEach(([step, comment]) => {
                    console.log("This is when we're processing: ", r)
                    if (step === selectedStep) {
                        // Update processedComments for the current step (right container)
                        const parsedText = parseText(comment);
                        updateStepData(step, {
                            processedComments: comment,
                            parts: parsedText.parts,
                            annotations: parsedText.annotations
                        });
                    } else {
                        // Create a new note for other steps
                        const newTranscript = {
                            timestamp: new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', second:'2-digit'}),
                            text: comment,
                            carriedOver: true
                        };
                        console.log("transcript that's supposed to go to the step '", step,"': ", newTranscript);
                        // Use the existing functions to save the new note
                        const stepData = getStepData(step);
                        updateStepData(step, {
                            ...stepData,
                            transcripts: [...(stepData.transcripts || []), newTranscript]
                        });
                    }
                });
        
                setIsProcessing(false);
            });
        };

        useEffect(() => {
            // Function to handle the P key press
            const handlePPress = (event) => {
                console.log("EDITING: ", editingTranscript)
                if (!editingTranscript) {
                    if ((event.code === 'p' || event.keyCode === 80) && !['input', 'textarea'].includes(document.activeElement.tagName.toLowerCase())) {  
                        event.preventDefault(); // Prevent default behavior (if any)
                        console.log('P key pressed'); // Debugging to check if the key is detected
                        processComments(); // Trigger the recording function
                    }
                }
            };
            // Add event listener for keydown
            window.addEventListener('keydown', handlePPress);
            // Cleanup the event listener when the component is unmounted
            return () => {
                window.removeEventListener('keydown', handlePPress);
            };
        }, [processComments], [editingTranscript]);
    
    /*
        - This function is called when the user clicks on the "Confirm answers" button
        - It sends the annotated answers to the backend in specific format
        - The backend processes the answers and returns the updated comments
    */

    const getGuidanceList = () => {
        if (showGuidancePopup) {
            setShowGuidancePopup(false);
        } else {
            api.guidanceList(selectedComponent, selectedStep).then(r => {
                const guidanceList = r && r.guidance_list && typeof r.guidance_list === 'string' 
                ? r.guidance_list 
                : '';
                setSelectedGuidance(r.guidance_list);
                console.log("Guidance List:", guidanceList);
                setShowGuidancePopup(true);
            });
        }
    };


    const handleMouseEnter = () => {
        setShowUserGuide(true);
      };

    const handleMouseLeave = () => {
        setShowUserGuide(false);
      };

      

    
    return (
        <Container>
            <Navbar steps={selectedMenu} selectedStep={selectedStep} onStepChange={newStep => setSelectedStep(newStep)} onSignOut={() => {
                    // Remove token and redirect to login
                    localStorage.removeItem('jwtToken');
                    window.location.href = '/login';
            }}/>
    <div style={{ position: 'absolute', top: '98px', left: '300px', zIndex: 1, display: 'flex', alignItems: 'center', gap: '10px' }}>
        <CustomButton 
            text={translate("startNewRepair")}
            onClick={handleNumberButtonClick}
            style={{ 
                height: '32px',
                padding: '0 12px'
            }}
        />
        {showNumberInput && (
            <>
                <CustomInput
                    type="number"
                    value={tempOrderNr}
                    onChange={(e) => setTempOrderNr(e.target.value)}
                    placeholder="Enter number"
                    style={{ 
                        width: '100px',
                        height: '32px'
                    }}
                />
                <CustomButton
                    text="Save"
                    iconName="FaSave"
                    onClick={handleOrderNumSave}
                    style={{ 
                        height: '32px',
                        padding: '0 12px'
                    }}
                />
            </>
        )}
        {orderNumber && !showNumberInput && (
            <span style={{ 
                fontSize: '1em',
                fontWeight: 'bold'
            }}>
                #{orderNumber}
            </span>
        )}
    </div>
            <Wrapper style={{ height: '100%' }}>
                <MainContainer data-container>
                    {/* Left side of the container - written/transcripted notes */}
                    <LeftSide>
                        <SideHeadline>
                            {translate("yourNotes")}
                        <CustomButton text = {translate("guidanceList")} iconName="FaInfo" onClick={() => { getGuidanceList(); }} style={{ width: '22%', padding: '8px 8px', fontSize: '0.8em'}}/> 
                        </SideHeadline>
                        <SideContentWrapper>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: 15, marginBottom: 15 }}>
                                <CustomSelect
                                    options={componentOptions}
                                    onChange={handleComponentChange}
                                    value={selectedComponent}
                                />
                                <CustomSelect style={{marginLeft: 10}}
                                    options={TRANSCRIPT_LANGUAGES}
                                    onChange={e => setCurrentLanguage(e.target.value)}
                                    value={currentLanguage}
                                />
                                <CustomButton style={{marginLeft: 10}} disabled={isTranscribing} text={isRecording ? translate("stopRecording") : translate("recordNote")} iconName={isRecording ? 'FaRegStopCircle' : 'FaMicrophone'} isRecording={isRecording} onClick={startRecording}/> 
                            </div>
                            <div style={{ width: '100%', flexGrow: 1, marginBottom: 15, border: '1px solid #ced4da', borderRadius: '4px', position: 'relative', display: 'flex', flexDirection: 'column', height: 'calc(100vh - 380px)'}}>
                                <div style={{ overflowY: 'auto', padding: '10px', flexGrow: 1, marginBottom: '40px' }} onClick={handleAreaClick} >
                                    {transcripts.map((transcript, index) => (
                                        <TranscriptDisplay
                                            key={index}
                                            transcript={transcript}
                                            onEdit={() => handleEditTranscript(index)}
                                            onDelete={() => handleDeleteTranscript(index)}
                                        />
                                    ))}
                                    {(isRecording || isTranscribing) && recordingStartTime && (
                                        <VoiceMemoDisplay startTime={recordingStartTime} isRecording={isRecording} />
                                    )}
                                </div>
                                <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, padding: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                                    <CustomButton
                                        text={translate("newNote")}
                                        iconName="FaPlus"
                                        onClick={handleNewNote}
                                        style={{ width: '20%', padding: '5px 10px', fontSize: '0.8em'}}
                                    />
                                </div>
                            </div>
                        </SideContentWrapper>
                    </LeftSide>
                    {showUserGuide && <UserGuide />}

                    <RightSide data-right-side>
                        <SideHeadline> {translate("sapInput")} - {selectedStep}  </SideHeadline>
                        <div style={{ position: 'absolute', marginBottom: 0, left: 0, right: 0, padding: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                        </div>
                        <SideContentWrapper>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15, marginBottom: 15 }}>
                            {isProcessing && <Spinner />}

                            {/* Edit/Save, Process Comments, and Confirm Answers Buttons */}
                            <CustomButton
                                style={{ marginLeft: 'auto', marginRight: 10 }} // Pushes all buttons to the right and adds space
                                text={isEditingOutput ? translate("save") : translate("edit")}
                                iconName={isEditingOutput ? 'FaSave' : 'FaEdit'}
                                disabled={isProcessing || annotations.length > 0 || parts.length === 0}
                                onClick={handleToggleEditOutput}
                            />
                            <CustomButton
                                style={{ marginRight: 10 }} // Space between Process Comments and Edit/Save
                                disabled={isProcessing}
                                text={translate("processComments")}
                                iconName='FaSync'
                                spinningIcon={isProcessing}
                                onClick={() => { processComments(); }}
                            />
                        </div>
                        <div style={{ position: 'absolute', left: '24px', bottom: '8px', display: 'flex', alignItems: 'center' }}>
                        <CustomButton 
                            onClick={goToPreviousStep} 
                            iconName="FaArrowLeft"
                            text={translate("previous")}
                            disabled={selectedMenu.indexOf(selectedStep) === 0}
                            style={{ marginRight: '520px' }} 
                        />
                        <CustomButton 
                            onClick={handleCopy} 
                            text={isCopied ? translate("copied") : translate("copy")} 
                            iconName="FaCopy"
                            style={{ marginRight: '335px' }} 
                        />
                        <CustomButton 
                            onClick={goToNextStep} 
                            text={translate("next")} 
                            disabled={selectedMenu.indexOf(selectedStep) === selectedMenu.length - 1}
                            iconName="FaArrowRight"
                        />
                        </div>
                            <ProcessedCommentContainer style={{ marginBottom: 15, flexGrow: 1, whiteSpace: 'pre-wrap' }}>
                                {/* The processed comments are rendered here, including their highlighted clickable parts */}
                                <ProcessedCommentWrapper>
                                    {parts.map((part, index) => {
                                        if (part.isAnnotated) return null; // Exclude annotations here
                                        return isEditingOutput ? (
                                            <textarea
                                                key={`edit_${index}`}
                                                value={editedParts[index] || part.text}
                                                onChange={(e) => handleEditPartChange(index, e.target.value)}
                                                style={{ width: '95%', marginBottom: '10px', height: '95%' }}
                                            />
                                        ) : (
                                            <span
                                                key={`display_${index}`}
                                                style={{
                                                    backgroundColor: hoveredHighlight === part.index ? '#ffff99' : 'transparent', // Highlight on hover
                                                    padding: '2px',
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        );
                                    })}</ProcessedCommentWrapper>


            <SuggestionsContainer>
                {
                    // The annotations parts are rendered here, including the input fields for the answers
                    (() => {
                        let annotatedPartsCount = 0;
                        return parts.map((part, index) => {
                            if(!part.isAnnotated) return null;
                            const currentAnnotationIndex = annotatedPartsCount++;

                            return (
                                <SuggestionWindow key={'suggestion_' + index}
                                        onMouseEnter={() => {
                                            setHoveredHighlight(part.index); }}
                                        onMouseLeave={() => {
                                            setHoveredHighlight(null); }}
                                    >
                                    <SuggestionText>{part.question}</SuggestionText>
                                    <div style={{display: 'flex', flexDirection: 'column', gap: 5, alignItems: 'center'}}>
                                    </div>
                                </SuggestionWindow>
                            )
                    });
                })()
            }
            </SuggestionsContainer>
                            </ProcessedCommentContainer>

                        </SideContentWrapper>
                    </RightSide>
                    {showGuidancePopup && (
                        <GuidanceListPopup 
                        guidanceList={selectedGuidance || ''}
                        onClose={() => setShowGuidancePopup(false)}
                        />
                    )}

                </MainContainer>
            </Wrapper>
                <div>
                    <CustomButton
                        text={translate("userGuide")}
                        iconName="FaInfo"
                        onMouseEnter={handleMouseEnter}  // Show UserGuide on hover
                        onMouseLeave={handleMouseLeave}  // Hide UserGuide when not hovering
                        style={{ position: 'absolute', left: '22px', bottom: '15px', backgroundColor: 'grey'}}
                        />
                </div>
                <div style={{ position: 'absolute', right: '22px', bottom: '15px'}}>
                    <DropZone  />
                </div>
                {editingTranscript && (
        <TranscriptEditor
            transcript={editingTranscript}
            onSave={(savedTranscript) => {
                handleSaveTranscript(savedTranscript);
                setEditingTranscript(null);
            }}
            onClose={() => setEditingTranscript(null)}
    />

    )}
    <OrderNumberModal 
        isOpen={showOrderModal}
        onSubmit={handleOrderNumSave}
        tempOrderNr={tempOrderNr}
        setTempOrderNr={setTempOrderNr}
    />
        </Container>
    );
}

export default Main;
